import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairPatchInDelhi = () => {

  const seoData = {
    title: 'Hair Patch In Delhi | Hair Patch For Men - Radiance',
    description: 'Discover effective solutions for hair patch in Delhi for men at Radiance Hair Studio. Regain a full head of hair with our expertly crafted hair patches.',
    keywords: ['Hair patch in delhi, hair patch for men, hair patch price, hair patch near me'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "areaServed": "New Delhi",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Hair-Patch-In-Delhi.jpg",
      "https://www.radiancehairstudio.com/hair-patch-men1.png",
      "https://www.radiancehairstudio.com/hair-patch-men2.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "serviceType": "Hair Patch In Delhi",
    "additionalType": "https://www.radiancehairstudio.com/hair-patch-mens.php",
    "category": "https://www.radiancehairstudio.com/hair-patch-mens.php",
    "description": "Discover effective solutions for hair patch in Delhi for men at Radiance Hair Studio. Regain a full head of hair with our expertly crafted hair patches.",
    "disambiguatingDescription": "Hair patch in delhi, hair patch for men, hair patch price, hair patch near me"
  };   

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Hair-Patch-In-Delhi.jpg",
      content: (
        <div>
          <h2>Hair Patch for Men</h2>
          <p>
          Unbelievably, the simplest and most efficient method of treating baldness is to use a hair patch.Typically, the hair patch referred to as HAIR WIG, is a skin-friendly cap-shaped device composed of natural hair with a ventilated lace or silicon basis. used to cover the bald area.Hair patches are now the most effective and reasonably priced <a href="/contact.php" style={{ color: "#373737"}}>treatment for hair loss</a>. 
          </p>
          <p>
          We all lose hair every day; it could be as much as 100 strands every day. However, generally speaking, hair goes out, grows back in, and the cycle repeats..However, many of us may soon begin to notice that our hair is not coming back and that we are losing it at a higher rate than previously.
          </p>
          <p>
          It may surprise you to learn that 85% of men will experience hair loss at some
          point in their lives. Hair Loss can happen for different reasons. 
          </p>
          <h2>Leave your worry BEHIND!!!</h2>

          <p>They can use a hair patch or wig when hair growth is not possible. The best thing is that hair patch/wig treatments are available to both men and women of any age.. Hair Patch is a non-surgical procedure so, you don’t need to be concern about any health-related issue.
          </p>
          <p>
          When considering hair patches or wigs, the most important question is whether they will
          appear natural.You can go anywhere without worrying because our hair patches remarkably resemble your own hair.<a href="/" style={{ color: "#373737"}}>Radiance Hair Studio</a> transforms your head, leaving it with a gorgeous smile and hair patches that look natural. 
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/hair-patch-men1.png",
      largeImg: "/hair-patch-men2.png",
      content: (
        <div>
          <h2>Benefits of using Men's Hair Patch in Delhi </h2>
  
          <ul    >
            <li>
            Low-cost technique - This Hair Replacement technique is cost-effective and still give a better and instant result then all other all.
            </li>
  
            <li>
            he desired hairstyle achieved - This process gives you the freedom to change your hairstyle as you want and when you want. If you have a short or medium length hairstyle and you prefer a long hairstyle. Anything can be done in no time.
            </li>
  
            <li>
            Medically approved procedure - This process is 100% Safe & Painless. There are no particular side effects and definitely, do not lead to allergies or reactions in most of the cases.
            </li>
            <li>
  
            Change your look - The ultimate advantage of Hair Patch is the density of hair it can offer. It can simply reverse your look you and make you 10 to 15 years younger.
            </li>
          </ul>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "FAQ's of Hair Patch",
      description:
        "At Radiance Hair Studio, we use advanced techniques to design customized hair patches in Delhi that seamlessly blend with your existing hair, ensuring comfort and durability. Our team of knowledgeable experts will assist you in finding the ideal fit, regardless of whether you're experiencing partial hair loss or total baldness.. ",
      description2: "Regain your youthful charm with the finest services of hair patch in Delhi at Radiance Hair Studio.Come see us today to start your path to a more self-assured you!",
      faq: [
        {
          title: "How much time this Procedure Take?",
          description:
            "Hair replacement procedure is very time efficient process and the quickest one also, it takes only 1 hour to make you bald to bold",
        },
        {
          title: "Can I go the gym with this Hair patch?",
          description:
            "Yes, you can do your daily chores with this patch, you can do gymming, swimming and riding also and don’t worry it won’t fall off.",
        },
        {
          title: "Can I choose any kind of hair Styl?",
          description:
            "Yes, you can opt for any kind of Hair Style with this Hair patch.",
        },
        {
          title: "How can I maintain with Hair patch?",
          description:
            "The best ways to maintain your hair wigs or other hair extension pieces are as follows: · Brush or comb your hair patch with a gentle hand. · Wash your hair wig with Non-Chemical shampoo and apply conditioner. · Use serum and leave-in conditioner for fixing your hair. · Use a hairdryer if required. · visit our hair wig center in 4-6 weeks duration for maintenance and care",
        },
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} canonical="https://www.radiancehairstudio.com/hair-patch-mens.php" />
        <Layout>
          <TitleBar2 title="Hair Patch In Delhi" banner="/hair-patch-in-delhi.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Men's Hair Patch in Delhi" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />

            <ServiceSectionFour data={sectionS4} />
            <div className="seo-section pt-2">
              <div className="myContainer">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                   <p className="text-center">At Radiance Hair Studio, we use advanced techniques to design customized hair patches in Delhi that seamlessly blend with your existing hair, ensuring comfort and durability. Whether you’re dealing with partial hair loss or complete baldness, our team of skilled professionals will help you find the perfect match.
                   </p>
                   <ul className="benefits-list">
                    <li>Why Choose Radiance Hair Studio?</li>
                    <li>High-quality, lightweight, and breathable hair patches</li>
                    <li>Customized designs for a natural look</li>
                    <li>Safe and non-invasive procedures</li>
                    <li>Long-lasting solutions with minimal maintenance</li>
                    </ul>

                   <p className="pt-2 text-center">Regain your youthful charm with the finest services of hair patch in Delhi at Radiance Hair Studio. Visit us today and embark on your journey to a more confident you!</p>
                  </div>
                </div>
              </div>
              </div>
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairPatchInDelhi;
